import type { BrowserHistory, Update } from 'history';
import { createBrowserHistory } from 'history';
import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import ContextProvider from 'packages/framework/ContextProvider';
import LocaleProvider from 'packages/framework/components/App/localeProvider';
import { ApolloProvider } from '@apollo/client';
import { Provider as ReduxProvider } from 'react-redux';
import { ToastContextProvider } from 'packages/framework/Toasts';

// @ts-ignore
import { createRoot, hydrateRoot } from 'react-dom/client';
import { EntryContext } from 'packages/framework/route/entry';
// import AppEntry from 'packages/framework/route/AppEntry';
import { createPageRoutes } from 'packages/util/createPageRoutes';
import MagentoRoute from 'packages/framework/components/MagentoRoute';
import { WindowSizeContextProvider } from 'packages/framework/hooks/useWindowSize';
import type { Workbox } from 'workbox-window';
import createStore from './store';
import './index.scss';
import './index.scss';
import { useAdapter } from 'packages/framework/hooks/Adapter/useAdapter';
import { lastTouchAttribution } from 'packages/util/gtm';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import App from './App.client';
import { createPagesForApp } from './page-routes';

function Routes({ routes }) {
  let element = useRoutes(routes);
  return <App>{element}</App>;
}

declare global {
  var __remixContext: EntryContext;
  var __remixManifest: EntryContext['manifest'];
  var workbox: Workbox;
}


const styles = new Set();
const isServer = !globalThis.document;
const origin = isServer
  ? import.meta.env.MAGENTO_BACKEND_URL
  : globalThis.location.origin;

const MainApp = props => {
  const talonProps = useAdapter({
    origin,
    store: createStore,
    styles,
  });
  const { apolloProps, initialized, reduxProps, routerProps, urlHasStoreCode } =
    talonProps;

  let historyRef = React.useRef<BrowserHistory>();
  if (historyRef.current == null) {
    historyRef.current = createBrowserHistory({ window });
  }

  let history = historyRef.current;
  let [state, dispatch] = React.useReducer(
    (_: Update, update: Update) => update,
    {
      action: history.action,
      location: history.location,
    },
  );
  React.useLayoutEffect(() => {
    history.listen(dispatch);
  }, [history]);

  useEffect(() => {
    lastTouchAttribution();
    (async () => {
      if ('serviceWorker' in navigator) {
        const { Workbox } = await import('workbox-window');
        const wb = new Workbox('/sw.js');
        window.workbox = wb;
        const reg = await wb.register();
      }
    })()
      .then(e => {
        console.log(e);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  const [routes] = useState(() => {
    const routes: any = import.meta.glob('./routes/**/*.server.[jt]sx');
    const pageRoutes = createPageRoutes(routes);

    const pages: any[] = pageRoutes.map(
      ({ path, componentLoader, exact }) => {
        let Lazy = lazy(componentLoader);
        let data = (window as any)?.INLINED_PAGE_TYPE
        if (data?.route !== path) {
          data = null
        }

        return {
          path,
          caseSensitive: false,
          element: (
            <Suspense fallback={null}>
              <Lazy data={data} />
            </Suspense>
          ),
        };
      },
    );

    pages.push({
      path: '*',
      caseSensitive: false,
      element: <MagentoRoute />,
    });
    return pages;
  });

  return (
    <ApolloProvider {...apolloProps}>
      <ReduxProvider {...reduxProps}>
        <LocaleProvider>
          <ContextProvider>
            <WindowSizeContextProvider>
              <ToastContextProvider>
                <BrowserRouter>
                  <Routes routes={routes} />
                </BrowserRouter>
                {/* <AppEntry
                  routes={routes}
                  context={entryContext}
                  action={state.action}
                  location={state.location}
                  navigator={history}
                /> */}
                {/* <ReloadPrompt /> */}
              </ToastContextProvider>
            </WindowSizeContextProvider>
          </ContextProvider>
        </LocaleProvider>
      </ReduxProvider>
    </ApolloProvider>
  );
};
// {/* </UseGraphQLProvider> */}

const root = document.getElementById('root');

if (root?.hasChildNodes()) {
  hydrateRoot(root, <MainApp />);
} else {
  let rootA;
  if (!rootA) {
    rootA = createRoot(root);
  }
  rootA.render(<MainApp />);
}
