import { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRootComponents } from '../../context/rootComponents';
import mergeOperations from 'packages/framework/util/shallowMerge';
import { useAppContext, useAppState } from 'packages/framework/context/app';

import { isRedirect } from './helpers';
import DEFAULT_OPERATIONS from './magentoRoute.gql';
import getRootComponent from 'packages/framework/RootComponents/getRootComponent';
import { useLazyQuery } from '@apollo/client';

const getInlinedPageData = () => {
  return globalThis.INLINED_PAGE_TYPE && globalThis.INLINED_PAGE_TYPE.type
    ? globalThis.INLINED_PAGE_TYPE
    : null;
};

const resetInlinedPageData = () => {
  globalThis.INLINED_PAGE_TYPE = false;
};

export const useMagentoRoute = (props: any = {}) => {
  const operations = mergeOperations(DEFAULT_OPERATIONS, props.operations);
  const { resolveUrlQuery } = operations;

  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [componentMap, setComponentMap] = useRootComponents();
  const urlKey = `${pathname.replace(/^\/+/, '')}${search}`;

  const initialized = useRef(false);
  const fetchedPathname = useRef(null);

  // const [appState, appApi] = useAppContext();
  const appApi = useAppContext();
  const appState = useAppState();
  const { actions: appActions } = appApi;
  const { nextRootComponent } = appState;
  const { setNextRootComponent, setPageLoading } = appActions;

  const setComponent = useCallback(
    (key, value) => {
      setComponentMap(prevMap => new Map(prevMap).set(key, value));
    },
    [setComponentMap],
  );
  const component = componentMap.get(pathname);

  const [runQuery, queryResult] = useLazyQuery(resolveUrlQuery, {
    fetchPolicy: 'no-cache',
    context: {
      isPrivate: false,
    },
  });
  // destructure the query result
  const { data, error, loading } = queryResult;
  const { route } = data || {};

  useEffect(() => {
    if (
      initialized.current ||
      (getInlinedPageData()?.urlKey !== urlKey && !getInlinedPageData())
    ) {
      runQuery({
        variables: { url: pathname },
      });
      fetchedPathname.current = pathname;
    }
    // To Be Uncommend Later
    // window.dispatchEvent(new Event('pageRequested', { bubbles: true }));
  }, [initialized, pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (component) {
      return;
    }

    (async () => {
      const { type, ...routeData } = route || {};
      const { id, identifier, uid } = routeData || {};
      const isEmpty = !id && !identifier && !uid;

      if (!type || isEmpty) {
        // To Be Uncommend Later
        // window.dispatchEvent(new Event('pageLoaded', { bubbles: true }));
        return;
      }
      try {
        const rootComponent = getRootComponent(type);
        setComponent(pathname, {
          component: rootComponent,
          ...routeData,
          type,
        });
      } catch (error) {
        if (import.meta.env.NODE_ENV !== 'production') {
          console.error(error);
        }

        setComponent(pathname, error);
      }
    })();
  }, [route]); // eslint-disable-line react-hooks/exhaustive-deps
  const { id, identifier, uid, redirect_code, relative_url, type } =
    route || {};

  // evaluate both results and determine the response type
  const empty = !route || !type || (!id && !identifier && !uid);
  const redirect = isRedirect(redirect_code);
  const fetchError = component instanceof Error && component;
  const routeError = fetchError || error;
  const isInitialized = initialized.current || !getInlinedPageData();
  let showPageLoader = false;
  let routeData;

  if (component && !fetchError) {
    // FOUND
    routeData = component;
  } else if (routeError) {
    // ERROR
    routeData = { hasError: true, routeError };
  } else if (redirect) {
    // REDIRECT
    routeData = {
      isRedirect: true,
      relativeUrl: relative_url.startsWith('/')
        ? relative_url
        : '/' + relative_url,
    };
  } else if (empty && fetchedPathname.current === pathname && !loading) {
    // NOT FOUND
    routeData = { isNotFound: true };
  } else if (nextRootComponent) {
    // LOADING with full page shimmer
    showPageLoader = true;
    routeData = { isLoading: true, shimmer: nextRootComponent };
  } else {
    // LOADING
    const isInitialLoad = !isInitialized;
    routeData = {
      isLoading: true,
      initial: isInitialLoad,
    };
  }

  useEffect(() => {
    (async () => {
      let inlinedData = getInlinedPageData();
      if (inlinedData?.type === 'CATEGORY') {
        inlinedData = {
          ...globalThis?.INLINED_PAGE_TYPE?.preloadData,
          urlKey: inlinedData?.urlKey,
        };
      }
      if (urlKey === inlinedData?.urlKey && inlinedData) {
        try {
          const componentType = inlinedData.type;
          const rootComponent = await getRootComponent(componentType);
          setComponent(pathname, {
            component: rootComponent,
            type: componentType,
            ...inlinedData,
          });
        } catch (error) {
          setComponent(pathname, error);
        }
      }
      initialized.current = true;
    })();

    return () => {
      // Unmount
      resetInlinedPageData();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // perform a redirect if necesssary
  useEffect(() => {
    if (routeData) {
      if (routeData.isNotFound) {
        window.isNotFound = routeData.isNotFound;
        // To Be Uncommend Later
        // window.dispatchEvent(new Event('pageLoaded', { bubbles: true }));
      } else if (routeData.isRedirect) {
        window.isRedirect = routeData.isRedirect;
        window.finalRedirectUrl = routeData.relativeUrl + search;
        // To Be Uncommend Later
        // window.dispatchEvent(new Event('pageLoaded', { bubbles: true }));
        navigate(routeData.relativeUrl + search, {
          replace: true,
        });
      }
    }
  }, [pathname, navigate, routeData]);

  useEffect(() => {
    if (component) {
      // Reset loading shimmer whenever component resolves
      setNextRootComponent(null);
    }
  }, [component, pathname, setNextRootComponent]);

  useEffect(() => {
    setPageLoading(showPageLoader);
  }, [showPageLoader, setPageLoading]);
  return routeData;
};
