import { bool, func } from 'prop-types';

import ProductsBlock from 'src/components/ProductsBlock';
import ResultsBlock from 'src/components/ResultsBlock';

import { useAmXsearchContext } from 'src/context';
import defaultClasses from './autocomplete.module.scss';
import { mergeClasses } from 'packages/framework/util/classify';

const Autocomplete = props => {
  const { valid, visible, setInputSearchValue, setIsAutoCompleteOpen } = props;

  const classes = mergeClasses(defaultClasses, props.classes);
  const rootClassName = visible ? classes.root_visible : classes.root_hidden;

  const { storeConfig } = useAmXsearchContext() || {};
  const { amasty_xsearch_product_position } = storeConfig || {};

  return (
    <div className={`${rootClassName} ${defaultClasses.searchContainer}`}>
      <ResultsBlock
        valid={valid}
        setInputSearchValue={setInputSearchValue}
        setIsAutoCompleteOpen={setIsAutoCompleteOpen}
      />
      {valid && (
        <ProductsBlock
          position={amasty_xsearch_product_position}
          setIsAutoCompleteOpen={setIsAutoCompleteOpen}
        />
      )}
    </div>
  );
};

export default Autocomplete;

Autocomplete.propTypes = {
  valid: bool,
  visible: bool,
  setInputSearchValue: func,
  setIsAutoCompleteOpen: func,
};
