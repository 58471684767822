import { gql } from '@apollo/client';

import { DiscountSummaryFragment } from './discountSummary.gql';
// import { GiftCardSummaryFragment } from './queries/giftCardSummary';
// import { GiftOptionsSummaryFragment } from './queries/giftOptionsSummary';
import { ShippingSummaryFragment } from './shippingSummary.gql';
import { TaxSummaryFragment } from './taxSummary.gql';

export const GrandTotalFragment = gql`
  fragment GrandTotalFragment on CartPrices {
    grand_total {
      currency
      value
    }
  }
`;

export const PriceSummaryFragment = gql`
  fragment PriceSummaryFragment on Cart {
    id
    # eslint-disable-next-line @graphql-eslint/require-id-when-available
    prices {
      ...TaxSummaryFragment
      ...DiscountSummaryFragment
      ...GrandTotalFragment
      subtotal_excluding_tax {
        currency
        value
      }
      subtotal_including_tax {
        currency
        value
      }
      cgst_charge {
        currency
        value
      }
      igst_charge {
        currency
        value
      }
      sgst_charge {
        currency
        value
      }
      other_charge {
        amount {
          currency
          value
        }
      }
    }
  }
  ${DiscountSummaryFragment}
  ${GrandTotalFragment}
  ${TaxSummaryFragment}
`;
// ...ShippingSummaryFragment
// ${ShippingSummaryFragment}
// // ${GiftCardSummaryFragment}
// // ${GiftOptionsSummaryFragment}
// ...GiftCardSummaryFragment
// ...GiftOptionsSummaryFragment
