import { lazy } from 'react';

const PRODUCT = lazy(() => import('RootComponents/Product'));
const CATEGORY = lazy(() => import('RootComponents/Category'));
const CMS_PAGE = lazy(() => import('RootComponents/CMS'));
// const SEARCH = () => import("packages/framework/RootComponents/CMS");
const componentMap = {
  PRODUCT,
  CATEGORY,
  CMS_PAGE,
};
export default function getRootComponent(type) {
  return componentMap[type];
}
