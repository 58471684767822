import { useLocation } from 'react-router-dom';
import CheckoutFooter from './checkoutFooter';
import GenericFooter from './genericFooter';

export default function Footer(props) {
  const location = useLocation();
  const isCheckout =
    location.pathname.includes('checkout') &&
    !location.pathname.includes('checkout/order-received') &&
    !location.pathname.includes('checkout/order-failed');
  return isCheckout ? <CheckoutFooter /> : <GenericFooter />;
}
