import React, { useEffect, useMemo, useState } from 'react';
import { object, func } from 'prop-types';

// import { mergeClasses } from '@magento/venia-ui/lib/classify';
import defaultClasses from './productsBlock.module.scss';

import Rating from '../Rating';
import { Link, useSearchParams } from 'react-router-dom';
import resourceUrl from 'packages/framework/util/makeUrl';

import { useProduct } from 'src/talons/useProduct';
import Button from 'packages/framework/components/Button';
import { mergeClasses } from 'packages/framework/util/classify';
import { useAmXsearchContext } from 'src/context';
import Price from 'packages/framework/components/Price';
import {
  deriveOptionCodesFromProduct,
  deriveOptionSelectionsFromProduct,
} from 'src/core/services/helpers/sharedProductCategory';
import { useFormContext } from 'react-hook-form';

interface Product {
  setIsAutoCompleteOpen: (flag: Boolean) => void;
  classes?: any;
  product: any;
}

const ProductsBlock = (props: Product) => {
  const { product, setIsAutoCompleteOpen } = props;
  const classes = mergeClasses(defaultClasses, props.classes);
  const { storeConfig } = useAmXsearchContext();
  const { setValue } = useFormContext();
  const [searchParams] = useSearchParams();
  // const { handleAddToCart } = useProduct({
  //   product,
  // });

  const {
    amasty_xsearch_product_add_to_cart,
    amasty_xsearch_product_reviews,
    amasty_xsearch_product_name_length,
  } = storeConfig;

  const derivedOptionSelections = useMemo(
    () => deriveOptionSelectionsFromProduct(product, searchParams),
    [product, searchParams],
  );

  const [optionSelections, setOptionSelections] = useState(
    derivedOptionSelections,
  );

  const attributeIdToValuesMap = useMemo(() => {
    const map = new Map();
    // For simple items, this will be an empty map.
    const options = product.configurable_options || [];
    for (const { attribute_id, values } of options) {
      map.set(
        attribute_id,
        values.reduce((m, v) => {
          m.set(v.value_index, v);
          return m;
        }, new Map()),
      );
    }
    return map;
  }, [product.configurable_options]);

  const derivedOptionCodes = useMemo(
    () => deriveOptionCodesFromProduct(product),
    [product],
  );
  const [optionCodes] = useState(derivedOptionCodes);

  const [selectedOptionsArray, optionsSelectedArray] = useMemo(() => {
    const selectedOptions = [];
    const optionsSelected = [];

    optionSelections?.forEach((value, key) => {
      const values = attributeIdToValuesMap.get(key);
      const code = optionCodes.get(key);
      const selectedValue = values.get(value);

      if (selectedValue) {
        selectedOptions.push(selectedValue.uid);
        optionsSelected.push({
          option_label: code,
          value_label: selectedValue.label,
        });
      }
    });
    return [selectedOptions, optionsSelected];
  }, [attributeIdToValuesMap, optionSelections]);

  const { url_key, url_suffix, rating_summary } = product;
  const url = resourceUrl(`/${url_key}${url_suffix}`);
  const ratingPercent = parseInt(rating_summary, 10);
  const ratingStarsQty = ratingPercent / 20; // 20 - 1 star coefficient

  const productName = amasty_xsearch_product_name_length
    ? product.name.slice(0, amasty_xsearch_product_name_length)
    : product.name;

  const handleDropDownClose = () => {
    setIsAutoCompleteOpen(false);
    setValue('search_query', '');
  };

  const currentProduct = useMemo(() => {
    return product?.variants?.find(item => {
      const { attributes } = item;

      return (
        attributes[0]?.label === optionsSelectedArray[0]?.value_label &&
        attributes[1]?.label === optionsSelectedArray[1]?.value_label
      );
    });
  }, [product]);

  const currentProductPrice = useMemo(() => {
    return currentProduct?.product?.price_range?.minimum_price?.final_price
      ?.value;
  }, [currentProduct]);
  const currency = useMemo(() => {
    return (
      currentProduct?.product?.price_range?.minimum_price?.final_price
        ?.currency || 'INR'
    );
  }, [currentProductPrice]);

  const percentageOff = Math.round(
    product?.price_range?.maximum_price?.discount?.percent_off,
  );

  return (
    <li onClick={handleDropDownClose}>
      <Link
        to={url}
        className="flex p-[1rem] my-[-1rem] items-center hover:bg-[#f4f5fa]"
      >
        <figure className={classes.imageContainer}>
          <img
            className={classes.image}
            src={`${product.image.url}`}
            alt={product.name}
          />
        </figure>
        <div className={classes.description}>
          <span className={classes.title}>{productName}</span>
          <div className="flex  sm:flex-wrap sm:flex-row flex-col items-left sm:items-center mt-2 justify-between">
            <div className="pb-1 flex flex-wrap items-center">
              <p className={classes.price}>
                <Price
                  currencyCode={currency || 'INR'}
                  value={currentProductPrice}
                />
              </p>
              <div className="font-medium text-sm ">
                {product?.price_range?.maximum_price?.discount?.percent_off >
                0 ? (
                  <>
                    <div className="flex flex-wrap justify-center text-xs">
                      <span className=" ml-2 text-[#707070] line-through">
                        <Price
                          value={
                            product?.price_range?.maximum_price?.regular_price
                              ?.value
                          }
                          currencyCode={
                            product?.price_range?.maximum_price?.regular_price
                              ?.currency
                          }
                        />
                      </span>

                      <span className=" ml-1 text-[#707070]">
                        {'('}
                        {percentageOff}
                        {'%'}&nbsp;{'off)'}
                      </span>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            {!product.is_salable && <span>{'Out of stock'}</span>}
          </div>
        </div>
      </Link>
    </li>
  );
};

export default ProductsBlock;
