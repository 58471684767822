import { gql } from '@apollo/client';
import { CheckoutPageFragment } from '../CheckoutPage/checkoutPageFragments.gql';

export const LOGIN_OTP = gql`
  mutation LoginOTP($mobileNumber: String!) {
    loginOTP(mobileNumber: $mobileNumber) {
      status
      message
    }
  }
`;

export const LOGIN_OTP_VERIFY = gql`
  query LoginOTPVerify($mobileNumber: String!, $otp: String!) {
    loginOTPVerify(mobileNumber: $mobileNumber, otp: $otp, websiteId: 1) {
      status
      message
      token
    }
  }
`;

export const GET_CUSTOMER = gql`
  query GetCustomerAfterSignIn {
    # eslint-disable-next-line @graphql-eslint/require-id-when-available
    customer {
      email
      firstname
      lastname
      is_subscribed
      mobilenumber
      orders {
        total_count
      }
    }
  }
`;

export const SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String!) {
    generateCustomerToken(email: $email, password: $password) {
      token
    }
  }
`;

export const CREATE_CART = gql`
  mutation CreateCartAfterSignIn {
    cartId: createEmptyCart
  }
`;

export const MERGE_CARTS = gql`
  mutation MergeCartsAfterSignIn(
    $sourceCartId: String!
    $destinationCartId: String!
  ) {
    mergeCarts(
      source_cart_id: $sourceCartId
      destination_cart_id: $destinationCartId
    ) {
      id
      # eslint-disable-next-line @graphql-eslint/require-id-when-available
      items {
        uid
      }
      ...CheckoutPageFragment
    }
  }
  ${CheckoutPageFragment}
`;

export const USER_CONSENT = gql`
  mutation userConsent($mobile: String!, $user_consent: Int!) {
    userConsent(mobile: $mobile, user_consent: $user_consent) {
      message
      status
    }
  }
`;

export default {
  createCartMutation: CREATE_CART,
  getCustomerQuery: GET_CUSTOMER,
  mergeCartsMutation: MERGE_CARTS,
  signInMutation: SIGN_IN,
  loginOtpMutation: LOGIN_OTP,
  loginOtpVerifyMutation: LOGIN_OTP_VERIFY,
  userConsentMutation: USER_CONSENT,
};
