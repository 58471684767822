import defaultClasses from './indicator.module.css';
import useStyle from 'packages/framework/util/shallowMerge';
import LoaderIcon from 'react-feather/dist/icons/loader';
import Icon from '../Icon';

const LoadingIndicator = props => {
  const classes = useStyle(defaultClasses, props.classes);
  const className = props.global ? classes.globalClass : classes.root;

  return (
    <div className={className} style={props.style}>
      <Icon src={LoaderIcon} size={64} classes={{ icon: classes.indicator }} />
      <span className={classes.message}>{props.children}</span>
    </div>
  );
};

export default LoadingIndicator;
