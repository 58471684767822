import { LogInComponent } from './LogInComponent';
import './authstyle.scss';

export const AuthComponent = ({ close, isCheckout = false }) => {
  return (
    <div className="max-w-[100%] md:max-w-[100%] mx-auto ">
      <LogInComponent close={close} className="login-form" />
    </div>
  );
};
