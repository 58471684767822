import React from 'react';
import { string } from 'prop-types';

import TYPES from './types';
import { fullPageLoadingIndicator } from 'packages/framework/components/LoadingIndicator';

const RootShimmer = props => {
    const { type = null } = props;

    if (!type || typeof TYPES[type] === 'undefined') {
        return fullPageLoadingIndicator;
    }

    const Component = TYPES[type];

    return <Component />;
};

// RootShimmer.defaultProps = {
//     type: null
// };

RootShimmer.propTypes = {
    type: string
};

export default RootShimmer;
