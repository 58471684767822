import { useCallback, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCartState } from 'packages/framework/context/cart';

/**
 * Routes to hide the mini cart on.
 */
const DENIED_MINI_CART_ROUTES = ['/checkout'];

/**
 *
 * @param {DocumentNode} props.queries.getItemCountQuery query to get the total cart items count
 *
 * @returns {
 *      itemCount: Number,
 *      miniCartIsOpen: Boolean,
 *      handleLinkClick: Function,
 *      handleTriggerClick: Function,
 *      miniCartRef: Function,
 *      hideCartTrigger: Function,
 *      setMiniCartIsOpen: Function
 *  }
 */
export const useCartTrigger = props => {
  const {
    queries: { getItemCountQuery },
  } = props;

  const { cartId } = useCartState();

  const { data } = useQuery(getItemCountQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      cartId,
    },
    skip: !cartId,
    errorPolicy: 'all',
  });

  const itemCount = data?.cart?.total_quantity || 0;

  return {
    itemCount,
  };
};
