import FormError from 'packages/framework/components/FormError/formError';
import { useSignIn } from 'packages/framework/hooks/SignIn/useSignIn';
import { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Button from 'components/Button';
import Form from 'src/components/Form';
import '../authstyle.scss';
import {
  GET_CART_DETAILS_QUERY,
  GET_CART_DETAILS_QUERY_CHECKOUT,
} from './signIn.gql';

import validateMobile from 'packages/framework/util/validators/validateMobile';
import { useAppContext, useAppState } from 'packages/framework/context/app';

const LoginViaOTP = ({
  handleSubmitOTP,
  createLoginOtpLoading,
  setMobileNumber,
  errors,
}) => {
  const methods = useForm();
  const {
    formState: { errors: formErrors },
  } = methods;

  const onSubmitWithOTP = async data => {
    let result = await handleSubmitOTP(data);
    result && setMobileNumber(data.mobilenumber);
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmitWithOTP)} className="py-4">
      <FormError allowErrorMessages errors={Array.from(errors.values())} />
      <Controller
        name="mobilenumber"
        control={methods.control}
        rules={validateMobile}
        render={({ field, fieldState, formState }) => (
          <Form.MobileNumberField
            className="login-otp-field"
            name={field.name}
            label="Phone Number"
            id="mobilenumber"
            type="tel"
            onBlur={field.onBlur}
            value={field.value || ''}
            onChange={e => {
              if (e.currentTarget.value?.length > 10) {
                e.currentTarget.value = e.currentTarget.value.substring(
                  e.currentTarget.value.length - 10,
                );
              }
              field.onChange(e.currentTarget.value);
            }}
            error={fieldState.error?.message}
          />
        )}
      />

      <div className="items-center grid grid-cols-1 gap-6 md:gap-6 mt-8">
        <Button
          disabled={createLoginOtpLoading}
          type="submit"
          className="buy_now_btn flex items-center justify-center bg-[#fbb716] text-[1.5rem] font-bold rounded-3xl h-[42px] text-xs text-[#050606]"
        >
          GENERATE OTP
        </Button>
      </div>
    </form>
  );
};

const VerifyOTP = ({
  mobileNumber,
  secondsRemaining,
  handleVerifyOTP,
  createLoginOtpVerifyLoading,
  createLoginOtpLoading,
  generateOtp,
  errors,
  setAgree,
  isAgree
}) => {
  const methods = useForm({
    mode: 'onChange',
  });

  const {
    register,
    reset,
    setValue,
    getValues,
    formState: { errors: formErrors },
  } = methods;

  const { redirectUrl } = useAppState()
  const { setHasToRedirect } = useAppContext();

  const onVerifyOTP = async data => {
    const result = await handleVerifyOTP(data);
    reset({
      agree: false,
      otp: '',
    });
    if(!!redirectUrl){
      setHasToRedirect({redirectUrl, allow:true})
    }
  };

  return (
    <div className="flex flex-col login-otp-field verify-otp-form">
      <FormError allowErrorMessages errors={Array.from(errors.values())} />
      <label className="m-0 form-label" htmlFor={mobileNumber}>
        Phone Number
      </label>
      <div className="control mobile-number-control">
        <select>
          <option
            defaultChecked
            value="91"
            data-display-text="India&nbsp; +91"
            data-display-selection="IN&nbsp; +91"
          >
            +91
          </option>
        </select>
        <div className="input flex items-center">{mobileNumber}</div>
      </div>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onVerifyOTP)}>
          <input
            type="hidden"
            name="mobilenumber"
            {...register('mobilenumber')}
          />
          <Form.FormInput
            name="otp"
            label="Enter OTP"
            id="otp"
            type="otp"
            className="login-field mt-4"
            {...register('otp', {
              required: {
                value: true,
                message: 'OTP is required',
              },
              pattern: {
                value: /^[0-9]{4}$/,
                message: 'Please enter 4 digit OTP',
              },
            })}
            error={formErrors.otp?.message}
          />
          <div className="flex items-start gap-x-2.5">
            <input
              name="agree"
              type="checkbox"
              {...register('agree')}
              onChange={e => {
                setValue('agree', e.target.checked ? true : false);
                setAgree(!isAgree);
              }}
            />
            <span className="text-white leading-[1.3] text-sm">
              I agree to the{' '}
              <a href="/terms-conditions" target="_blank" className="underline">
                terms and conditions
              </a>{' '}
              and{' '}
              <a href="/privacy-policy" target="_blank" className="underline">
                privacy policy
              </a>
            </span>
          </div>
          <div className="text-brand text-right mt-3">
            {secondsRemaining > 0 ? (
              <div>Resend in {secondsRemaining}s</div>
            ) : (
              <strong
                className={`${
                  createLoginOtpLoading
                    ? 'cursor-not-allowed'
                    : 'cursor-pointer'
                }`}
                onClick={
                  createLoginOtpLoading
                    ? () => {}
                    : () => generateOtp(mobileNumber)
                }
              >
                {createLoginOtpLoading ? 'Sending OTP' : 'Resend OTP'}
              </strong>
            )}
          </div>
          <div className="items-center grid grid-cols-1 md:grid-cols-1 gap-10 mt-8">
            <Button
              disabled={!isAgree || createLoginOtpVerifyLoading}
              type="submit"
              onClick={() => setValue('mobilenumber', mobileNumber)}
              className="buy_now_btn flex items-center justify-center bg-[#fbb716] text-[1.5rem] rounded-3xl h-[42px] text-xs text-[#050606] font-bold"
            >
              VERIFY OTP
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export const LogInComponent = props => {
  const { close, className, isCheckout = false } = props;
  const [mobileNumber, setMobileNumber] = useState(false);
  const methods = useForm({
    mode: 'onChange',
  });

  const { reset } = methods;

  const handleLoginSubmitMain = () => {
    close();
    reset();
  };

  const talonProps = useSignIn({
    getCartDetailsQuery: isCheckout
      ? GET_CART_DETAILS_QUERY_CHECKOUT
      : GET_CART_DETAILS_QUERY,
    setDefaultUsername: {},
    showCreateAccount: {},
    showForgotPassword: {},
    onSubmit: handleLoginSubmitMain,
    onCancel: {},
  });

  const {
    handleSubmitOTP,
    isOTPAvailable,
    createLoginOtpLoading,
    secondsRemaining,
    handleVerifyOTP,
    createLoginOtpVerifyLoading,
    generateOtp,
    errors,
    setAgree,
    isAgree,
  } = talonProps;

  return (
    <div
      className={`${className} w-full bg-[#292929] px-[2rem] md:pt-[3rem] pb-[3rem] rounded-b-[32px] flex flex-col md:flex-row items-center justify-center`}
    >
      {/* {loginWay?.login_password && (
        <LoginViaPassword close={close} setLoginWay={setLoginWay} />
      )} */}
      <div className="flex w-full justify-center md:justify-start md:w-[50%]">
        <img src="/images/Logo-new.svg" alt="Atomberg" className="w-[220px]" />
      </div>
      <div className="w-full md:w-[50%] flex ml-auto md:border-l-[1px] md:border-[#fbb716]">
        <div className="form-container w-full md:w-[85%] md:ml-auto">
          <h2 className="text-white text-center text-2xl text-[#fbb716] font-semibold mb-[15px]">
            Login/Sign Up
          </h2>
          {!isOTPAvailable ? (
            <LoginViaOTP
              handleSubmitOTP={handleSubmitOTP}
              createLoginOtpLoading={createLoginOtpLoading}
              setMobileNumber={setMobileNumber}
              errors={errors}
            />
          ) : (
            <VerifyOTP
              mobileNumber={mobileNumber}
              secondsRemaining={secondsRemaining}
              createLoginOtpLoading={createLoginOtpLoading}
              handleVerifyOTP={handleVerifyOTP}
              createLoginOtpVerifyLoading={createLoginOtpVerifyLoading}
              generateOtp={generateOtp}
              errors={errors}
              setAgree={setAgree}
              isAgree={isAgree}
            />
          )}
        </div>
      </div>
    </div>
  );
};
