import { useAmXsearchContext } from 'src/context';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

const wrapUseSearchBars = original => props => {
  const { storeConfig, singleProduct } = useAmXsearchContext() || {};
  const [valid, setValid] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const defaultReturnData = original(props);

  const { setIsAutoCompleteOpen, handleQuerySubmit, setInputSearchValue } =
    defaultReturnData || {};

  const {
    amasty_xsearch_general_min_chars: minChars,
    amasty_xsearch_product_redirect_single_product: isRedirectToSingleProduct,
  } = storeConfig || {};

  const handleChange = useCallback(
    e => {
      const value = e.target.value;
      const hasValue = !!value;
      const isValid = hasValue && value.length >= 1;
      setInputSearchValue(value);
      setValid(isValid);
      setIsAutoCompleteOpen(hasValue);
    },
    [minChars, setValid, setIsAutoCompleteOpen],
  );

  const prepareToSubmit = useCallback(
    search_query => {
      const { enabled, url_key } = singleProduct || {};
      if (isRedirectToSingleProduct && enabled) {
        setIsAutoCompleteOpen(false);
        return navigate(url_key);
      } else {
        return handleQuerySubmit(search_query);
      }
    },
    [isRedirectToSingleProduct, navigate, handleQuerySubmit, singleProduct],
  );

  const handleFocus = useCallback(() => {
    setIsAutoCompleteOpen(true);
  }, [setIsAutoCompleteOpen]);

  useEffect(() => {
    setIsAutoCompleteOpen(false);
  }, [setIsAutoCompleteOpen]);

  return {
    ...defaultReturnData,
    handleChange,
    handleFocus,
    handleQuerySubmit: prepareToSubmit,
    valid,
  };
};

export default wrapUseSearchBars;
