import { gql } from '@apollo/client';
import { AppliedCouponsFragment } from '../../CartPage/PriceAdjustments/CouponCode/couponCodeFragments.gql';
import { PriceSummaryFragment } from '../../CartPage/PriceSummary/priceSummaryFragments.gql';
import { ProductListingFragment } from '../../CartPage/ProductListing/productListingFragments.gql';
import {
  AvailablePaymentMethodsFragment,
  SelectedPaymentMethodFragment,
} from '../PaymentInformation/paymentInformation.gql';
import { ShippingInformationFragment } from '../ShippingInformation/shippingInformationFragments.gql';

export const OrderConfirmationPageFragment = gql`
  fragment OrderConfirmationPageFragment on Cart {
    id
    pan_number
    email
    total_quantity
    ...SelectedPaymentMethodFragment
    ...ShippingInformationFragment
    ...ProductListingFragment
    ...PriceSummaryFragment
    ...AppliedCouponsFragment
    ...AvailablePaymentMethodsFragment
  }
  ${AppliedCouponsFragment}
  ${ProductListingFragment}
  ${PriceSummaryFragment}
  ${ShippingInformationFragment}
  ${AvailablePaymentMethodsFragment}
  ${SelectedPaymentMethodFragment}
`;
