import React from 'react';
import { shape, string, bool, object, func } from 'prop-types';
import { AVAILABLE_ITEMS_CODES } from '../../constants';

// import classes from './resultsBlock.css';
import defaultClasses from './resultsBlock.module.scss';

import Item from './item';
import { mergeClasses } from 'packages/framework/util/classify';

const Items = props => {
  const {
    valid,
    item,
    storeConfig,
    setInputSearchValue,
    setIsAutoCompleteOpen,
  } = props;
  const { code } = item;
  const classes = mergeClasses(defaultClasses, props.classes);
  if (
    (!valid &&
      (!AVAILABLE_ITEMS_CODES.includes(code) ||
        !storeConfig?.[`amasty_xsearch_${code}_first_click`])) ||
    !Array.isArray(item.items) ||
    !item.items.length ||
    !storeConfig?.[`amasty_xsearch_${code}_enabled`]
  ) {
    return null;
  }

  const titleLength = storeConfig[`amasty_xsearch_${code}_name_length`];
  const titleName = storeConfig[`amasty_xsearch_${code}_title`];
  const title = titleLength ? titleName.slice(0, titleLength) : titleName;

  const limit = storeConfig[`amasty_xsearch_${code}_limit`];
  const position = storeConfig[`amasty_xsearch_${code}_position`];

  const list = item.items
    .slice(0, limit)
    .map((listItem, index) => (
      <Item
        key={listItem.name + index}
        item={listItem}
        code={code}
        setInputSearchValue={setInputSearchValue}
        setIsAutoCompleteOpen={setIsAutoCompleteOpen}
      />
    ));

  return (
    <div
      className={`${classes.block} ${classes[code]}`}
      style={{ order: position }}
    >
      <p className={classes.head}>{title}</p>
      <ul className={classes.list}>{list}</ul>
    </div>
  );
};

export default Items;

Items.propTypes = {
  valid: bool,
  submitForm: func,
  item: shape({
    configName: string,
    queryName: string,
    query: object,
  }),
  storeConfig: object,
  setInputSearchValue: func,
  setIsAutoCompleteOpen: func,
};
