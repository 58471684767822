import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import useStyle from 'packages/framework/util/shallowMerge';
// import Button from 'packages/framework/components/Button';
import defaultClasses from './errorView.module.css';
import backgroundUrl from './errorViewBackground-1400x600.png';
import mobileBackgroundUrl from './errorViewBackground-380x600.png';
import Button from '../Button';
import { StoreTitle } from '../Head';
import Page404 from 'src/routes/404/index.server';

const DEFAULT_HEADER = 'Oops!';
const DEFAULT_MESSAGE = 'Looks like something went wrong. Sorry about that.';
const DEFAULT_PROMPT = 'Take me home';

const ErrorView = props => {
  const classes = useStyle(defaultClasses, props.classes);
  const navigate = useNavigate();

  const handleGoHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const {
    header = (
      <FormattedMessage
        id={'errorView.header'}
        defaultMessage={DEFAULT_HEADER}
      />
    ),
    message = (
      <FormattedMessage
        id={'errorView.message'}
        defaultMessage={DEFAULT_MESSAGE}
      />
    ),
    buttonPrompt = (
      <FormattedMessage
        id={'errorView.goHome'}
        defaultMessage={DEFAULT_PROMPT}
      />
    ),
    onClick = handleGoHome,
  } = props;

  const handleClick = useCallback(() => {
    onClick && onClick();
  }, [onClick]);

  const style = {
    '--backroundImageUrl': `url("${backgroundUrl}")`,
    '--mobileBackgroundImageUrl': `url("${mobileBackgroundUrl}")`,
  };

  return (
    <div /* className={classes.root} style={style} */ data-cy="ErrorView-root">
      <StoreTitle>Not Found</StoreTitle>
      <Page404 />

      {/* <div className={classes.content}>
        <p className={classes.header}>{header}</p>
        <p className={classes.message} data-cy="ErrorView-message">
          {message}
        </p>
        <div className={classes.actionsContainer}>
          <Button priority="high" type="button" onClick={handleClick}>
            {buttonPrompt}
          </Button>
        </div>
      </div> */}
    </div>
  );
};

export default ErrorView;
