import { gql } from '@apollo/client';

export const GET_ITEM_COUNT_QUERY = gql`
    query getItemCount($cartId: String!) {
        cart(cart_id: $cartId) {
            id
            total_quantity
        }
    }
`;
