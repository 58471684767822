export enum AnalyticsEvents {
  GA4_CLIENT_ID = 'GA4_CLIENT_ID',

  ADD_SHIPMENT_INFO_EVENT_NAME = 'add_shipping_info',
  ADD_SHIPMENT_INFO_ERROR_EVENT_NAME = 'add_shipping_info_error',

  BEGIN_CHECKOUT_EVENT_NAME = 'begin_checkout',
  BEGIN_CHECKOUT_ERROR_EVENT_NAME = 'begin_checkout_error',

  PURCHASE_EVENT_NAME = 'purchase',

  PAYMENT_COD_EVENT_NAME = 'add_payment_info_cod',
  COD_PAYMENT_UPDATE_ON_CART_EVENT_NAME = 'cod_payment_on_cart',
  COD_PAYMENT_UPDATE_ON_ERROR_CART_EVENT = 'cod_payment_on_cart_error',

  PAYMENT_RAZORPAY_EVENT_NAME = 'add_payment_info_razorpay',
  PAYMENT_RAZORPAY_ERROR_EVENT_NAME = 'add_payment_info_razorpay_error',
  RAZORPAY_PAYMENT_UPDATE_ON_CART_EVENT_NAME = 'razorpay_payment_on_cart',
  RAZORPAY_PAYMENT_UPDATE_ON_ERROR_CART_EVENT = 'razorpay_payment_on_cart_error',

  CART_UPDATED_EVENT_NAME = 'cart_updated',
  ADD_TO_CART_ERROR_EVENT_NAME = 'add_to_cart_error',
  REMOVE_FROM_CART_ERROR_EVENT_NAME = 'remove_from_cart_error',
  CART_CLEAT_EVENT_NAME = 'cart_clear',
  CART_CLEAR_ERROR_EVENT_NAME = 'cart_clear_error',

  PLACE_ORDER_EVENT_NAME = 'place_order',
  PLACE_ORDER_ERROR_EVENT_NAME = 'place_order_error',
}
