import { useWindowSize } from 'packages/framework/hooks/useWindowSize';
import { Link } from 'react-router-dom';

const links = [
  { name: 'Privacy policy', href: '/privacy-policy' },
  { name: 'Shipping policy', href: '/shipping-policy' },
  { name: 'Cancellation & returns', href: '/cancellations-returns' },
];

export default function NewFooter() {
  return (
    <footer className="bg-[#262626]" aria-labelledby="footer-heading">
      <div className="container mx-auto">
        <div className="relative md:max-w-[85%] mx-auto">
          <div className="max-w-xl mx-auto text-center">
            <div className="grid grid-cols-3 gap-x-2 py-4 gap-x-4">
              {links.map(link => (
                <a
                  href={link.href}
                  key={link.name}
                  target="_blank"
                  className="text-[13px] text-[#ACACAC] font-normal"
                >
                  {link.name}
                </a>
              ))}
            </div>
            <p className="text-xs text-gray-400 pb-4">
              Copyright &copy; 2020 Atomberg – All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
