import { gql } from '@apollo/client';
import { CartPageFragment } from 'packages/framework/hooks/CartPage/cartPageFragments.gql';
import { OrderConfirmationPageFragment } from 'packages/framework/hooks/CheckoutPage/OrderConfirmationPage/orderConfirmationPageFragments.gql';

export const GET_CART_DETAILS_QUERY_CHECKOUT = gql`
  query GetCartDetailsAfterSignInCheckout($cartId: String!) {
    cart(cart_id: $cartId) {
      id
      ...OrderConfirmationPageFragment
    }
  }
${OrderConfirmationPageFragment}`;

export const GET_CART_DETAILS_QUERY = gql`
  query GetCartDetailsAfterSignIn($cartId: String!) {
    cart(cart_id: $cartId) {
      id
      # eslint-disable-next-line @graphql-eslint/require-id-when-available
      items {
        uid
        # eslint-disable-next-line @graphql-eslint/require-id-when-available
        product {
          uid
          name
          sku
          small_image {
            url
            label
          }
          price_range {
            minimum_price {
              discount {
                amount_off
                percent_off
              }
              regular_price {
                currency
                value
              }
              final_price {
                currency
                value
              }
            }
          }
        }
        quantity
        # eslint-disable-next-line @graphql-eslint/require-id-when-available
        ... on ConfigurableCartItem {
          # eslint-disable-next-line @graphql-eslint/require-id-when-available
          configurable_options {
            configurable_product_option_uid
            option_label
            configurable_product_option_value_uid
            value_label
          }
        }
      }
      prices {
        grand_total {
          value
          currency
        }
      }
      ...CartPageFragment
    }
  }
  ${CartPageFragment}
`;
