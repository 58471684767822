import { AuthComponent } from '.';
import { AuthWrapper } from './AuthWrapper';
import './authstyle.scss';

// TODO - Add portal and wrapper
// Need to add Modal Component for Portal
// Need to handle modal open or close via context api or useReducer
export default function AuthModal({
  showModal,
  setOpenModal,
  isCheckout = false,
}) {
  const showHideClassName = showModal ? 'display-block' : 'hide-modal';

  // TODO - Move this things here from header
  // const talonProps = useAuthModal();
  // const { handleClose, isOpen } = talonProps;
  // const showHideClassName = isOpen ? ' display-block' : 'hide-modal';

  const closeModal = () => {
    setOpenModal();
  };

  return (
    <AuthWrapper
      showHideClassName={showHideClassName}
      showModal={showModal}
      close={closeModal}
    >
      <AuthComponent close={closeModal} isCheckout={isCheckout} />
    </AuthWrapper>
  );
}
