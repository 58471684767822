import { gql } from '@apollo/client';
import {
  GET_CATEGORY1,
  GET_CATEGORY_OTHER_DATA,
  GET_CATEGORY_CMS_DATA,
} from 'src/queries/getPullInitialDataGraphql.gql';

export const RESOLVE_URL = gql`
  query ResolveURL($url: String!) {
    route(url: $url) {
      relative_url
      redirect_code
      type
      ... on CmsPage {
        identifier
      }
      # eslint-disable-next-line @graphql-eslint/require-id-when-available
      ... on ProductInterface {
        uid
        __typename
      }
      # eslint-disable-next-line @graphql-eslint/require-id-when-available
      ... on CategoryInterface {
        uid
        meta_title
        meta_keywords
        meta_description
        url_path
        canonical_url
        category_desktop_img: image
        category_mobile_img
        codilar_category_banner_slider {
          title
          is_show_title
          is_enabled
          slider_id
          banners {
            slider_id
            sort_order
            is_mobile
            resource_type
            resource_path
            link
            alt_text
            title
            thumbnail
          }
        }
        name
        breadcrumbs {
          category_uid
          category_name
          category_level
          category_url_path
        }
      }
    }
  }
`;

export default {
  resolveUrlQuery: RESOLVE_URL,
  getCategory: GET_CATEGORY1,
  getCategoryOtherData: GET_CATEGORY_OTHER_DATA,
  getCategoryCmsData: GET_CATEGORY_CMS_DATA,
};
