import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
// import resourceUrl from '@magento/peregrine/lib/util/makeUrl';
import resourceUrl from 'packages/framework/util/makeUrl';
import { shape, string, func } from 'prop-types';
// import classes from './resultsBlock.css';
import defaultClasses from './resultsBlock.module.scss';

const toHTML = str => ({ __html: str });
import { AVAILABLE_ITEMS_CODES, URLS } from '../../constants';
// import Trigger from '@magento/venia-ui/lib/components/Trigger';
import Trigger from 'packages/framework/components/Trigger';
import { mergeClasses } from 'packages/framework/util/classify';
import { useFormContext } from 'react-hook-form';

const Item = props => {
  const { item, code, setInputSearchValue, setIsAutoCompleteOpen } = props;
  const { setValue } = useFormContext();
  const classes = mergeClasses(defaultClasses, props.classes);
  const isAvailableCodes = AVAILABLE_ITEMS_CODES.includes(code);
  const name = isAvailableCodes
    ? item.name.replace(/(<([^>]+)>)/gi, '')
    : item.name;
  const url = isAvailableCodes ? `${URLS.SEARCHURLPARAMS}${name}` : item.url;

  const handleDropDownClose = () => {
    setIsAutoCompleteOpen(false);
    setValue('search_query', '');
  };

  const title = (
    <Fragment>
      <span dangerouslySetInnerHTML={toHTML(name)} />
      {code !== 'recent_searches' && item.num_results && (
        <span className={classes.counter}>{item.num_results}</span>
      )}
    </Fragment>
  );

  const action = AVAILABLE_ITEMS_CODES.includes(code) ? (
    <Trigger
      classes={{ root: classes.link }}
      action={() => setInputSearchValue(name)}
    >
      {title}
    </Trigger>
  ) : (
    <Link className={classes.link} to={resourceUrl(url)}>
      {title}
    </Link>
  );

  return (
    <li className={classes.item} onClick={handleDropDownClose}>
      <Link className={classes.link} to={resourceUrl(url)}>
        {title}
      </Link>
    </li>
  );
};

export default Item;

Item.propTypes = {
  item: shape({
    code: string,
    name: string,
    num_results: string,
    url: string,
  }),
  code: string,
  setInputSearchValue: func,
  setIsAutoCompleteOpen: func,
};
