import Button from 'packages/framework/components/Button';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import './404.scss';

export default function Page404() {
  const navigate = useNavigate();
  const handleGoHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <div className="error-404-wrapper">
      <div className="container">
        <div className="content-area">
          <header className="page-header">
            <h1 className="page-title">Sorry, that page doesn't exist! </h1>
          </header>

          <div className="page-content">
            <h1 className="error-heading">404</h1>
            <p className="error-title">
              <img src="/images/sp-404-sad-face.png" />
            </p>
            <p className="error-title">Page not found!</p>

            <Button className="bg-brand" onClick={handleGoHome}>
              Take me Home
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
