import { findMatchingVariant } from 'packages/framework/util/findMatchingProductVariant';
import { isProductConfigurable } from 'packages/framework/util/isProductConfigurable';
const INITIAL_OPTION_SELECTIONS = new Map();
const OUT_OF_STOCK_CODE = 'OUT_OF_STOCK';
const INITIAL_OPTION_CODES = new Map();

// Similar to deriving the initial codes for each option.
export const deriveOptionSelectionsFromProduct = (
  product,
  searchParams,
  varianToBeSelected = null,
) => {
  if(!product.variants) product.variants = [];
  if (!isProductConfigurable(product)) {
    return INITIAL_OPTION_SELECTIONS;
  }
  let isSearchParamExist = false;
  let sku = searchParams.get('sku');

  for (const key of searchParams.keys()) {
    if (key.startsWith('attribute_pa') || key.endsWith('[filter]')) {
      isSearchParamExist = true;
    }
  }

  let variant: any = [];
  if (isSearchParamExist) {
    variant = [...product.variants].filter(({ attributes, product }) => {
      const {
        price_range: {
          minimum_price: { final_price },
        },
      } = product;

      const squareFitMapping = {
        77: '600mm',
        78: '900mm',
        79: '1200mm',
        80: '1400mm',
      };

      let found = true;
      for (const { code, label } of attributes) {
        // To handle atomberg's current marketing logic

        const [min, max] = (searchParams.get(`price[filter]`) &&
          searchParams
            .get(`price[filter]`)
            .toString()
            .split(',')[0]
            .split('-')) || [0, 0];

        let allParams = [];
        if (searchParams.getAll(`${code}[filter]`).length) {
          allParams = searchParams
            .getAll(`${code}[filter]`)
            .map(item => item.toString().split(',')[0].toLowerCase());
        } else if (searchParams.getAll(`attribute_pa_${code}`).length) {
          allParams = searchParams
            .getAll(`attribute_pa_${code}`)
            .map(item => item.toLowerCase());
        } else if (
          code == 'sweepsize' &&
          searchParams.getAll(`${'room_size'}[filter]`).length
        ) {
          allParams = searchParams
            .getAll(`${'room_size'}[filter]`)
            .map(item =>
              squareFitMapping[item.toString().split(',')[1]].toLowerCase(),
            );
        }

        if (
          min &&
          (final_price.value < min || final_price.value > max) &&
          allParams.length &&
          !allParams.includes(label.toLowerCase())
        ) {
          found = false;
        }

        if (
          (min && (final_price.value < min || final_price.value > max)) ||
          (allParams.length && !allParams.includes(label.toLowerCase()))
        ) {
          found = false;
        }
      }
      return found;
    });
  }

  let comparisonKeys =
    varianToBeSelected &&
    Object.keys(varianToBeSelected)?.filter(key => {
      if (varianToBeSelected[key]?.label) {
        return true;
      } else {
        return false;
      }
    });

  if (comparisonKeys?.length) {
    variant = product.variants?.filter(({ attributes }) => {
      return comparisonKeys?.every(key => {
        return attributes?.some(item => {
          return item?.label === varianToBeSelected[key]?.label;
        });
      });
    });
  }

  if (!variant.length) {
    // Select option based on hero_varianty
    if (product.hero_variant || sku) {
      variant = product.variants.filter(
        item => item.product.sku === (sku || product.hero_variant),
      );
    } else if (!variant.length) {
      // Select option based on quantity
      variant = [...product.variants].sort(
        (a, b) =>
          (b.product?.only_x_left_in_stock || 0) -
          (a.product?.only_x_left_in_stock || 0),
      );
    } else {
      //Select based on is option not an out of stock
      variant = product.variants.filter(
        item => item.product.stock_status !== OUT_OF_STOCK_CODE,
      );
    }
  }

  variant = (variant.length && variant[0]) || product.variants[0];

  var valueMap = new Map();
  if (variant) {
    valueMap = (variant.attributes || []).reduce(
      (map, { code, value_index }) => map.set(code, value_index),
      new Map(),
    );
  }
  const initialOptionSelections = new Map();
  for (const { attribute_id, attribute_code } of (product?.configurable_options || [])) {
    initialOptionSelections.set(attribute_id, valueMap.get(attribute_code));
  }
  return initialOptionSelections;
};

export const deriveOptionCodesFromProduct = product => {
  // If this is a simple product it has no option codes.
  if (!isProductConfigurable(product)) {
    return INITIAL_OPTION_CODES;
  }

  // Initialize optionCodes based on the options of the product.
  const initialOptionCodes = new Map();
  for (const { attribute_id, attribute_code } of (product.configurable_options || [])) {
    initialOptionCodes.set(attribute_id, attribute_code);
  }
  return initialOptionCodes;
};

export const getIsMissingOptions = (product, optionSelections) => {
  // Non-configurable products can't be missing options.
  if (!isProductConfigurable(product)) {
    return false;
  }

  // Configurable products are missing options if we have fewer
  // option selections than the product has options.
  const { configurable_options = [] } = product;
  const numProductOptions = configurable_options.length;
  const numProductSelections =
    optionSelections &&
    Array.from(optionSelections.values()).filter(value => !!value).length;

  return numProductSelections < numProductOptions;
};

export const getIsOutOfStock = (product, optionCodes, optionSelections) => {
  const { stock_status, variants } = product;
  const isConfigurable = isProductConfigurable(product);
  const optionsSelected =
    Array.from(optionSelections.values()).filter(value => !!value).length > 0;

  if (isConfigurable && optionsSelected) {
    const item = findMatchingVariant({
      optionCodes,
      optionSelections,
      variants,
    });
    if (!item) return true;
    return item.product.stock_status === OUT_OF_STOCK_CODE;
  }
  return stock_status === OUT_OF_STOCK_CODE;
};

export const getNumberInStock = (product, optionCodes, optionSelections) => {
  const { stock_status, only_x_left_in_stock, variants } = product;
  const isConfigurable = isProductConfigurable(product);
  const optionsSelected =
    Array.from(optionSelections.values()).filter(value => !!value).length > 0;

  if (isConfigurable && optionsSelected) {
    const item = findMatchingVariant({
      optionCodes,
      optionSelections,
      variants,
    });
    if (!item) return true;
    return item.product.only_x_left_in_stock;
  }
  return only_x_left_in_stock;
};
