import { ProductListingFragment } from "./ProductListing/productListingFragments.gql";
import { PriceSummaryFragment } from "packages/framework/hooks/CartPage/PriceSummary/priceSummaryFragments.gql";
import { AppliedCouponsFragment } from "packages/framework/hooks/CartPage/PriceAdjustments/CouponCode/couponCodeFragments.gql";
import { gql } from "@apollo/client";

export const CartPageFragment = gql`
  fragment CartPageFragment on Cart {
    id
    total_quantity
    ...AppliedCouponsFragment
    ...ProductListingFragment
    ...PriceSummaryFragment
  }
  ${AppliedCouponsFragment}
  ${ProductListingFragment}
  ${PriceSummaryFragment}
`;

// Commenting for now
// ...GiftCardFragment
// ${GiftCardFragment}
