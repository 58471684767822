import { RegisterOptions } from 'react-hook-form';
import validateRequired from './validateRequired';
const validateMobile: RegisterOptions = {
  ...validateRequired,
  minLength: {
    value: 10,
    message: 'Mobile number is not valid',
  },
  maxLength: {
    value: 10,
    message: 'Mobile number is not valid',
  },
  pattern: {
    value: /^[6-9]\d{9}$/,
    message: 'Invalid Mobile number',
  },
};

export default validateMobile;
