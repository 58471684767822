import React, { Suspense } from 'react';
// import { useIntl } from 'react-intl';
import { useMagentoRoute } from 'packages/framework/hooks/MagentoRoute';
import ErrorView from 'packages/framework/components/ErrorView';
import RootShimmerComponent from 'packages/framework/RootComponents/Shimmer';
import { Outlet } from 'react-router';

const MESSAGES = new Map()
  .set(
    'NOT_FOUND',
    "Looks like the page you were hoping to find doesn't exist. Sorry about that.",
  )
  .set('INTERNAL_ERROR', 'Something went wrong. Sorry about that.');

const MagentoRoute = () => {
  // const { formatMessage } = useIntl();
  const hookProps = useMagentoRoute();

  const {
    component: RootComponent,
    isLoading,
    isNotFound,
    isRedirect,
    shimmer,
    initial,
    ...componentData
  } = hookProps;

  if ((isLoading || isRedirect) && !import.meta.env.SSR) {
    // Show root component shimmer
    if (shimmer) {
      return <RootShimmerComponent type={shimmer} />;
    }

    return initial ? null : <RootShimmerComponent />;
  } else if (RootComponent) {
    return (
      <Suspense fallback={<RootShimmerComponent />}>
        <RootComponent {...componentData} />
        <Outlet />
      </Suspense>
    );
  } else if (isNotFound) {
    window['notFound'] = true;
    return (
      <ErrorView
        // message={formatMessage({
        //     id: 'magentoRoute.routeError',
        //     defaultMessage: MESSAGES.get('NOT_FOUND')
        // })}
        message={MESSAGES.get('NOT_FOUND')}
      />
    );
  }

  return <ErrorView message={MESSAGES.get('INTERNAL_ERROR')} />;
};

export default MagentoRoute;
