import { Fragment, useMemo } from 'react';
import { number, func } from 'prop-types';
import LoadingIndicator from 'packages/framework/components/LoadingIndicator';

import { mergeClasses } from 'packages/framework/util/classify';

import defaultClasses from './productsBlock.module.scss';

import { Link } from 'react-router-dom';
import Product from './product';
import { useAmXsearchContext } from 'src/context';
import { useProducts } from 'src/talons/useProducts';
import { useFormContext, useWatch } from 'react-hook-form';

interface ProductsBlock {
  position: number;
  setIsAutoCompleteOpen: (flag: Boolean) => void;
  classes?: any;
}

const ProductsBlock = (props: ProductsBlock) => {
  const { position, setIsAutoCompleteOpen } = props;

  const classes = mergeClasses(defaultClasses, props.classes);

  const { control } = useFormContext();
  const value = useWatch({
    control,
    name: 'search_query',
  });

  const { storeConfig } = useAmXsearchContext();
  const { amasty_xsearch_product_title, amasty_xsearch_product_limit: limit } =
    storeConfig;

  const { isProductsShowed, items, total_count, viewAllUrl, loading } =
    useProducts({
      inputText: value,
    });

  const message = (
    <span className={classes.message} style={{ order: position }}>
      {'Your search returned no products.'}
    </span>
  );

  const list = useMemo(
    () =>
      isProductsShowed &&
      items
        .slice(0, limit)
        .filter(item => item.type_id !== 'virtual' && item.type_id !== 'simple')
        .map(item => (
          <Product
            key={item.id}
            product={item}
            setIsAutoCompleteOpen={setIsAutoCompleteOpen}
          />
        )),
    [items, limit, isProductsShowed],
  );

  return (
    <Fragment>
      {loading ? (
        <span className={`${defaultClasses.loader} w-full flex justify-center`}>
          <LoadingIndicator />
        </span>
      ) : !isProductsShowed ? (
        <span>{message}</span>
      ) : null}
      {isProductsShowed ? (
        <div className={classes.root} style={{ order: position }}>
          <h4 className={classes.head}>{amasty_xsearch_product_title}</h4>
          <ul className={classes.items}>{list}</ul>
        </div>
      ) : null}
    </Fragment>
  );
};

export default ProductsBlock;
