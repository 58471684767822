import { useEffect } from 'react';
import defaultOperations from 'src/queries/getSearchProducts.gql';

import { string } from 'prop-types';
import resourceUrl from 'packages/framework/util/makeUrl';

import { useItems } from 'src/talons/useItems';
import { URLS } from 'src/constants';
import { useAmXsearchContext } from 'src/context';

const QUERY_NAME = 'xsearchProducts';

export const useProducts = props => {
  const { inputText = '' } = props;
  const { setSingleProduct } = useAmXsearchContext();
  const { GET_SEARCH_PRODUCTS } = defaultOperations;
  const { data, loading } = useItems({
    inputText: inputText,
    query: GET_SEARCH_PRODUCTS,
    queryName: QUERY_NAME,
  });

  const { items, total_count } = data || {};
  const isProductsShowed = Array.isArray(items) && items.length;
  const viewAllUrl = resourceUrl(`${URLS.SEARCHURLPARAMS}${inputText}`);

  useEffect(() => {
    if (total_count === 1) {
      const product = { ...items[0] };
      const { url_key, url_suffix } = product;
      const url = resourceUrl(`/${url_key}${url_suffix}`);

      setSingleProduct({
        enabled: true,
        url_key: url,
      });
    } else {
      setSingleProduct(false);
    }
  }, [items, total_count]);

  return {
    viewAllUrl,
    total_count,
    items,
    isProductsShowed,
    loading,
  };
};

useProducts.propTypes = {
  inputText: string,
};
