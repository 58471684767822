import React, { useRef } from 'react';
import { useButton } from 'react-aria';
import clsx from 'clsx';

/**
 * A component for buttons.
 *
 * @typedef Button
 * @kind functional component
 *
 * @param {props} props React component props
 *
 * @returns {React.Element} A React component that displays a single button.
 */
const Button = props => {
  const {
    children,
    className,
    classes: propClasses,
    priority,
    negative,
    disabled,
    onPress,
    p = 'px-6',
    ...restProps
  } = props;
  const buttonRef = useRef();

  const { buttonProps } = useButton(
    {
      isDisabled: disabled,
      onPress,
      ...restProps,
    },
    buttonRef,
  );

  return (
    <button
      ref={buttonRef}
      {...buttonProps}
      {...restProps}
      className={clsx(
        'h-10 items-center justify-center font-bold disabled:text-[#acacac] disabled:opacity-75 disabled:cursor-not-allowed',
        className,
        p,
      )}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  priority: 'normal',
  type: 'button',
  negative: false,
  disabled: false,
};

export default Button;
