import React from 'react';
import defaultClasses from './toast.module.css';
import mergeClasses from 'packages/framework/util/shallowMerge';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { XIcon } from '@heroicons/react/outline';
import Button from 'packages/framework/components/Button';
import Icon from '../Icon';

import CloseIcon from 'react-feather/dist/icons/x';
import clsx from 'clsx';

const Toast = props => {
  const {
    actionText,
    dismissable,
    hasDismissAction,
    dismissActionText = 'Close',
    icon,
    message,
    onAction,
    handleAction,
    onDismiss,
    handleDismiss,
    type,
    isModal,
    title,
  } = props;

  const classes = mergeClasses(defaultClasses, {});
  const iconElement = icon ? <>{icon}</> : null;

  const controls =
    onDismiss || dismissable ? (
      <button className={classes.dismissButton} onClick={handleDismiss}>
        <Icon src={CloseIcon} attrs={{ width: 14 }} />
      </button>
    ) : null;

  const dismissActionButton =
    hasDismissAction && (onDismiss || dismissable) ? (
      <button
        data-cy="Toast-dismissActionButton"
        className={clsx({
          [classes.dismissButton]: isModal,
          [classes.actionButton]: !isModal,
        })}
        onClick={handleDismiss}
      >
        {dismissActionText}
      </button>
    ) : null;

  const actions = onAction ? (
    <button
      data-cy="Toast-actionButton"
      className={clsx({
        [classes.isModalButton]: isModal,
        [classes.actionButton]: !isModal,
      })}
      onClick={handleAction}
    >
      {actionText}
    </button>
  ) : null;

  if (isModal) {
    return (
      <Transition.Root show={true} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-50"
          onClose={handleAction}
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed " />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="absolute top-2/4 left-2/4 -translate-x-[50%] -translate-y-[50%] p-[18px] bg-white transition-all sm:my-8 w-[320px]">
                {/* <div className="block absolute top-1 right-1 pr-2 z-[10]">
                  {controls}
                </div> */}
                <div
                  className={`flex flex-col bg-[#292929] p-8 rounded-[32px] h-full items-center`}
                >
                  <h1 className="text-white text-2xl">{title}</h1>
                  <p className="text-white my-[30px]">{message}</p>
                  <div className={clsx('flex w-full grid gap-2 grid-cols-1')}>
                    {actions}
                    {dismissActionButton}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
  return (
    <div
      className={`${classes[`${type}Toast`]} ${isModal ? classes.isModal : ''}`}
      data-cy="Toast-root"
    >
      <span className={classes.icon}>{iconElement}</span>
      <div className={classes.message} data-cy="Toast-message">
        {message}
      </div>
      <div className={classes.controls}>{controls}</div>
      {actions}
    </div>
  );
};

export default Toast;
